.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

input[type="text"],
input[type="password"] {
  /* height: 25px; */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
}
.dummy-button {
	color: rgba(255, 255, 255);
	padding: 13px 32px;
	height: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	flex-shrink: 0;
	white-space: nowrap;
	background-color: #1345ac;
	line-height: 24px;
	font-weight: bold;
	font-family: Arial;
	margin: 0;
	border: #1345ac;

}
input[type="submit"] {
	color: rgba(255, 255, 255);
	padding: 13px 32px;
	height: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	flex-shrink: 0;
	white-space: nowrap;
	background-color: #1345ac;
	line-height: 24px;
	font-weight: bold;
	font-family: Arial;
	margin: 0;
	border: #1345ac;

}

input[type="submit"]:hover {
	cursor: pointer !important;
	display: inline-table !important;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  width: 540px;
  padding: 2rem;
  box-shadow: 0 3px 5px rgb(0 0 0 / 10%);
  font-family: Nunito,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 16px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.my-5 {
	margin-bottom: 3rem!important;
	margin-top: 3rem!important;
}

.custlogin-5 {
	margin-bottom: 1rem!important;
	margin-top: 12rem!important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}